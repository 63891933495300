<template>
  <div class="patient-list">
  <el-drawer
    title="新增医生信息"
    size="530px;"
    :visible="visible"
    @close="handleClose">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="50px" class="doctor-info">
      <el-form-item label="姓名" prop="doctorName">
        <el-input class="form-ipt" placeholder="请输入医生姓名" v-model="ruleForm.doctorName"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="username">
        <el-input class="form-ipt" placeholder="请输入登录账号" v-model="ruleForm.username">
        </el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input class="form-ipt" placeholder="请输入登录密码" type="password" show-password v-model="ruleForm.password"></el-input>
      </el-form-item>
    </el-form>
    <div class="demo-drawer__footer">
      <el-button class="footer-btn" @click="handleClose()">取消</el-button>
      <el-button class="footer-btn" type="primary" @click="handleSave()">{{ '保存' }}</el-button>
    </div>
  </el-drawer>
  <el-dialog
    title="请确认开户信息"
    :visible.sync="dialogVisible"
    width="30%">
      <span>姓名：{{ruleForm.doctorName}}</span>
      <br>
       <span>账号：{{ruleForm.username}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createDoctorAccount()">确 定</el-button>
      </span>
  </el-dialog>
  </div>
</template>

<script>
import { createDoctorAccount } from '@/service/module/doctor'
import md5 from 'js-md5'
export default {
  components: {

  },
  data () {
    return {
      ruleForm: {
        doctorName: '',
        username: '',
        password: ''
      },
      rules: {
        doctorName: [
          { required: true, message: '请输入医生姓名', trigger: 'change' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5A-Za-z·]+$/, message: '请输入中文/英文/·' }
        ],
        username: [
          { required: true, message: '请输入登录账号', trigger: 'change' }, { min: 1, max: 32, message: '长度在 1 到 32 个字符', trigger: 'blur' }, { pattern: /^[A-Za-z\d]+$/, message: '账号格式有误，请输入登录账号，例如：zhenxy123' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'change' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
          { pattern: /^[A-Za-z\d._]+$/, message: '密码格式有误，请输入6-20位英文、数字、符号（.和_）' }
        ]
      },
      dialogVisible: false
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    handleClose () {
      this.$refs.ruleForm.resetFields()
      this.$emit('update:visible', false)
    },
    handleSave () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // this.ruleForm.password = md5(this.ruleForm.password)
          // 二次确认弹窗：
          this.dialogVisible = true
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    createDoctorAccount () {
      createDoctorAccount({
        doctorName: this.ruleForm.doctorName,
        username: this.ruleForm.username,
        password: md5(this.ruleForm.password)
      }).then(res => {
        if (res.code === 200) {
          this.$emit('update:visible', false)
          window.location.reload()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.demo-drawer__footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background-color: white;
}
.footer-btn {
  width: 74px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.form-ipt {
  width: 426px;
  height: 32px;
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}

::v-deep .el-input {
  width: 426px;
  height: 32px;
}
::v-deep .el-form-item__label  {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  padding: 0 8px 0 0;
  text-align: left;
}
::v-deep .el-form-item {
  margin: 24px 24px 0px 24px;
}
::v-deep .el-drawer__header {

}
</style>
