<template>
  <div>
    <el-scrollbar style="height: 100%">
      <div>
        <el-button style="float: right; margin-bottom: 12px;" type="primary" @click="openAccount">创建医生账号</el-button>
      </div>
      <table-comp
        :table-opts="tableOpts"
        :data="tableData"
        :page="currentPage"
        :total="totalPage"
        :page-size="pageSize"
        :show-number="true"
        :handle-opts="handleOpts"
        @handleSizeChange="sizeChange"
        @handleCurrentChange="pageChange"
      >
        <template #btn_1="{ msg }">
          <el-tooltip content="登录权限" placement="top" effect="light">
            <el-switch
              v-model="msg.isEnable"
              @change="other_1(msg)"
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </el-tooltip>
        </template>
      </table-comp>
      <DoctorOA :visible.sync="visibleDoctorOA"></DoctorOA>
    </el-scrollbar>
  </div>
</template>

<script>
import TableComp from '@/components/Table/Table'
import DoctorOA from './Page_DoctorOA/index.vue'
import { getDoctorList, opnDoctorAccount } from '@/service/module/doctor'
export default {
  components: {
    TableComp,
    DoctorOA
  },
  data () {
    return {
      tableOpts: [ // 列表表头设置
        {
          label: '医生名称',
          prop: 'doctorName',
          render: (h, params) => {
            const doctorName = params.row.doctorName
            const doctorNameText = '-'
            if (!doctorName) {
              return h('span', {}, doctorNameText)
            } else {
              return h('span', {}, doctorName)
            }
          }
        },
        {
          label: '账号',
          prop: 'username',
          render: (h, params) => {
            const username = params.row.username
            const usernameText = '-'
            if (!username) {
              return h('span', {}, usernameText)
            } else {
              return h('span', {}, username)
            }
          }
        },
        {
          label: '创建人',
          prop: 'creater',
          render: (h, params) => {
            const creater = params.row.creatorName
            const createrText = '-'
            if (!creater) {
              return h('span', {}, createrText)
            } else {
              return h('span', {}, creater)
            }
          }
        },
        {
          label: '开户时间',
          prop: 'createTime',
          render: (h, params) => {
            var moment = require('moment')
            const createTime = moment.unix(params.row.createTime / 1000).format('Y-MM-DD HH:mm:ss')

            const createTimeText = '-'
            if (!params.row.createTime) {
              return h('span', {}, createTimeText)
            } else {
              return h('span', {}, createTime)
            }
          }
        },
        {
          label: '账号状态',
          prop: 'isEnable',
          type: 'switch',
          active_value: 1,
          inactive_value: 0,
          handler: (data) => {
            this.tableData[data].isEnable === 0 ? this.tableData[data].isEnable = 1 : this.tableData[data].isEnable = 0
            this.$confirm(`请确认是否${this.tableData[data].isEnable === 1
            ? '冻结'
            : '开启'}该账号?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              var type = this.tableData[data].isEnable === 0 ? 'enable' : 'disable'

              this.opnDoctorAccount(type, this.tableData[data].id)
            }).catch(() => {
              console.log('取消冻结操作')
            })
          }
        }
      ],
      tableData: [],
      handleOpts: {
      },
      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20, // 每页条数

      visibleDoctorOA: false
    }
  },
  mounted () {
    this.getDoctorList(1, this.pageSize)
  },
  methods: {
    getDoctorList (pageNum, pageSize) {
      getDoctorList({
        pageNum: pageNum,
        pageSize: pageSize
      }).then(res => {
        if (res.data) {
          this.tableData = res.data.results
          this.totalPage = res.data.total
        }
      })
    },
    sizeChange (val) {
      this.pageSize = val
      this.getDoctorList(this.currentPage, val)
    },
    pageChange (val) {
      this.currentPage = val
      this.getDoctorList(val, this.pageSize)
    },
    openAccount (val) {
      console.log(val)
      this.visibleDoctorOA = true
    },
    opnDoctorAccount (type, id) {
      opnDoctorAccount({
        type: type,
        id: id
      }).then(res => {
        if (res.code === 200) {
          this.getDoctorList()
          this.$message.success(res.message)
        } else {
          this.getDoctorList()
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
